<template>
  <div class="navbar">
    <img :src="logo" alt="Logo" class="navbar-logo">
    
    <button v-if="isMobile && !isx" @click="toggleMenu" class="menu-button">☰</button>
    <button v-if="isMobile && isx" @click="toggleMenu" class="menu-button">X</button>

    <div class="navbar-dropdown" v-if="showMenu">
      <div class="navbar-links2">
        <router-link to="/" class="navbar-link2">Home</router-link>
        <router-link to="/projects" class="navbar-link2">Projects</router-link>
        <router-link to="/about" class="navbar-link2">About</router-link>
        <router-link to="/contact" class="navbar-link2">Contact</router-link>
      </div>
    </div>

    <div class="navbar-links" v-if="!isMobile">
      <div class="dropdown2">
        <router-link to="/" class="navbar-link"><h4>Home</h4></router-link>
      </div>
      <div class="dropdown">
        <router-link to="/projects" class="navbar-link"><h4>Projects</h4></router-link>
        <div class="dropdown-content">
            <router-link to="/projects/team" class="navbar-link2">3D Mapping</router-link>
            <router-link to="/projects/history" class="navbar-link2">Exhibitions</router-link>
            <router-link to="/projects/history" class="navbar-link2">Image & Music</router-link>
            <router-link to="/projects/history" class="navbar-link2">Retail</router-link>
            <router-link to="/projects/history" class="navbar-link2">AI</router-link>
            <!-- Add more links if needed -->
        </div>
      </div>
      <div class="dropdown2">
        <router-link to="/about" class="navbar-link" ><h4>About</h4></router-link>
      </div>
      <div class="dropdown2">
        <router-link to="/contact" class="navbar-link"><h4>Contact</h4></router-link>
      </div>
    </div>
  </div>
</template>

    
<script>
export default {
    name: "NavBar",
    data() {
    return {
      logo: require('@/assets/logo.png'),
      showFullScreenBar: false,
      showMenu: false,
      isx: false,
      isMobile: window.innerWidth <= 500, // assuming 768px is your breakpoint for mobile devices
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      if(!this.isMobile) {
        this.showMenu = false; // Close the menu if it's open and window resized to desktop
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      this.isx = !this.isx;
    }
  }
}
</script>

<style>
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: black;
    padding: 0.1vw 0.1vw;
    z-index: 500;
}
  
.navbar-logo {
    height: 7vh;
    min-height: 40px;
    padding: 0.5vw 0.5vh;
    margin-left: 20vw;
}
  
.navbar-links {
    display: flex;
    gap: 1vw;
    margin-right: 20vw;
    margin-top: 1vh;
}
  
.navbar-link {
    color: white;
    text-decoration: none;
    padding: 0.1vw 0.1vw;
    border-radius: 0.8vw;
    transition: background-color 0.2s;
}
.navbar-link2 {
    color: white;
    text-decoration: none;
    padding: 0.1vw 1vw;
    border-radius: 0.8vw;
}
  
.navbar-link:hover {
    background-color: #333;
}
.navbar-link2:hover {
    background-color: #333;
}

.dropdown {
    position: static; /* Change from relative to static so the dropdown-content positions relative to the navbar instead */
    display: inline-block;
}
.dropdown2 {
    position: static; /* Change from relative to static so the dropdown-content positions relative to the navbar instead */
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0; 
    right: 0; /* Stretch to the right edge of the screen */
    background-color: black;
    white-space: nowrap; 
    z-index: 2;
    border-radius: 0.5vw 0.5vw 0 0; /* Adjusted to only have top border radius */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.8);
    padding: 0.5vw 0;
    margin-top: -1vh;
}

.dropdown-content .navbar-link {
    display: inline-block; 
    padding: 0 0.5vw; 
    margin: 0; 
    width: calc(100% / [number of items]); /* Divide screen width equally among items. Replace [number of items] with your actual number, e.g., 2 for "Team" and "History" */
    text-align: center; /* Center the text for each link */
}

.dropdown:hover .dropdown-content {
    display: block;
}

.menu-button {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    margin-right: 10vw;
}

.navbar-dropdown {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.8);
    z-index: 1000;
    width: auto;  /* adjust width as needed */
    text-align: center;
    padding-top: 15%; /* Adjust based on your design to start links from near the middle */
}

.navbar-dropdown .navbar-link2 {
    display: block;
    width: 100%;
    text-align: left;
    padding: 0.5vw;
}

@media (max-width: 768px) { 
    .navbar-dropdown {
        display: block; /* make sure the container is always block, but the content visibility is toggled */
    }
}
.navbar-links2 {
    display: flex;
    flex-direction: column; /* Align items in a column */
    align-items: center; /* Center links horizontally */
    gap: 1rem; /* Space between links */
    justify-content: center; /* Center links vertically */
    height: 100%;
}

.navbar-links2 .navbar-link2 {
    color: white;
    text-decoration: none;
    padding: 0.5vw;
    text-align: center;  /* Center the text of the link */
    font-size: 1.5rem; /* Adjust size if necessary */
}
</style>
    