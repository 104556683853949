<template>
  <div>
    <NavBar />
    <full-page ref="fullpage" :options="options" id="fullpage">
      <div class="section">
          <div class="video-background">
            <video data-keepplaying autoplay  playsinline muted loop>
              <source :src="videoSrc" type="video/mp4">
            </video>
          </div>
          <div class="box">
            <div class="vue-title">
              DotLab
              <div class="vue-subtitle">
                We are Art we are Technology
              </div>
            </div>
          </div>  
        </div>
      <div class="section">
          <div class="background-image">
            <div class="box3">
              <swiper
                :spaceBetween="30"
                :centeredSlides="true"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                :modules="modules"
                class="mySwiper"
              >
                <swiper-slide><img :src="images[0]"></swiper-slide>
                <swiper-slide><img :src="images[1]"></swiper-slide>
              </swiper>
            </div>
            <div class="box2" @click="goToAboutrView">
              <div class="vue-title2">
                About us
                <div class="vue-subtitle2">
                  Based in a remote setting, our studio thrives on creativity 
                  and innovation. Continually seeking the perfect 
                  inspiration, our projects not only reflect our artistic and technological 
                  ambiance but also resonate with the evolving trends of the digital age. Our 
                  commitment to excellence and our drive to stay at the forefront ensures that 
                  our works stand out, blending the best of both worlds.
                </div>
                <!--
                  <div>
                    <v-icon style="font-size: calc(min(25px, 5vw));" icon="mdi-play" @click="goToAboutrView"/>
                  </div>
                -->
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="section fp-auto-height" style=" background-color: black;">
          <div class="slide" style="color: white; text-align: left;">
            Three
          </div>
        </div>
      -->
      </full-page>
    </div>
  </template>
  
  <script>
  // Import Swiper Vue.js components
  //import { register } from 'swiper/element/bundle';
  // register Swiper custom elements
  //register();

  import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
  import 'swiper/css';


  import './style.css';

  // import required modules
  import { Autoplay } from 'swiper/modules';
  
  import NavBar from '@/components/NavBar.vue'
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
      NavBar,
    },
    setup() {
      return {
        modules: [Autoplay],
      };
    },
    data() {
      return {
        logo: require('@/assets/logo.png'),
        images: [
          require('@/assets/ao.jpg'),
          require('@/assets/jc.jpg'),
        ],
        options: {
          licenseKey: "4L29I-I3R7J-2DI7J-DXPSJ-WSUGO",
          credits: {enabled: false},
          navigation: true,
          navigationPosition: "right"
        },
        videoSrc: require('@/assets/video_home.mp4'),
      };
    },
    mounted() {
    },
    methods: {
      navigateHome() {
        // Using the fullpage API to navigate to 'page2'
        this.$refs.fullpage.api.moveTo('page1', 1);
      },
      goToAboutrView() {
        // Use Vue Router's push method to navigate
        this.$router.push({ name: 'about' }); // 'another' is the name of the route you want to navigate to
      }
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  nav {
    padding: 30px;
  }
  
  nav a {
    font-weight: bold;
    color: #2c3e50;
  }
  
  nav a.router-link-exact-active {
    color: #42b983;
  }
  
  .video-background {
    position: fixed;   /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw;     /* Full viewport width */
    height: 100vh;    /* Full viewport height */
    overflow: hidden;
    z-index: -1;      /* Place video behind other content */
  }
  
  .video-background video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100vw;
    min-height: 100vh;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  
  /**  
  .box {
    background: rgba(255, 255, 255, 0.01);
    position: fixed;   
    top: 25vh;         
    right: 40vw;       
    width: 25vw;
    min-width: 200px;      
    height: 25vw;     
    min-height: 200px;
    overflow: hidden; 
    backdrop-filter: blur(5px);
  
    border-radius: 50%;
  
    display: flex;          
    align-items: center;    
    justify-content: center;
    text-align: center;     
    padding: 10px;          
    overflow: hidden;       
  }
  */

  .box {
    background: rgba(255, 255, 255, 0.01);
    position: fixed;       
    top: 50%;              
    left: 50%;             
    transform: translate(-50%, -50%); 
    width: calc(min(45vw, 45vh));  /* Set width relative to the smaller dimension between viewport width and height */
    height: calc(min(45vw, 45vh)); /* Same as above for height */
    min-width: 200px;      
    min-height: 200px;
    overflow: hidden;      
    backdrop-filter: blur(5px);
  
    border-radius: 50%;  /* Keeps the box as a circle */
  
    display: flex;           
    align-items: center;     
    justify-content: center; 
    text-align: center;      
    padding: 10px;           
    overflow: hidden;        
}

  .box2 {
    background: rgba(255, 255, 255, 0.02);
    position: fixed;   /* Fixed positioning relative to the viewport */
    top: 130vh;         /* 10 pixels from the top */
    right: 15vw;       /* 10 pixels from the right */
    width: 25vw;      /* Fixed width */
    height: 25vw;     /* Fixed height to make it a square */
    min-width: 250px;      
    min-height: 250px;
    overflow: hidden;  /* Hide any overflowing content */
    backdrop-filter: blur(5px);
  
    /* Circle Shape */
    border-radius: 5%;
  
    /* Content Alignment */
    display: flex;           /* Flexbox for vertical alignment */
    align-items: center;     /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    /*text-align: center;       Center text */
    padding: 10px;           /* Padding to give some space for the content */
    overflow: hidden;        /* Hide any overflowing content */
  }

  .box3 {
    background: rgba(255, 255, 255, 0.05);
    position: fixed;   /* Fixed positioning relative to the viewport */
    top: 130vh;        /* 10 pixels from the top */
    left: 15vw;        /* 10 pixels from the right */
    width: 25vw;       /* Fixed width */
    height: 25vw;      /* Fixed height to make it a square */
    min-width: 250px;      
    min-height: 250px;
    overflow: hidden;  /* Hide any overflowing content */
    backdrop-filter: blur(5px);
    border-radius: 5%;  /* Rounded borders */

    /* Content Alignment */
    display: flex;           /* Flexbox for vertical alignment */
    align-items: center;     /* Vertically center content */
    justify-content: center; /* Horizontally center content */
    text-align: center;      /* Center text */
    padding: 0px;           /* Padding to give some space for the content */
    overflow: hidden;        /* Hide any overflowing content */
}
  
  .vue-title {
    font-family: 'Times New Roman', Times, serif;
    font-size: calc(20px + 3vw);
    color: white;
  }
  
  .vue-subtitle {
    font-family: 'Times New Roman', Times, serif;
    font-size: calc(7px + 1vw);
    color: white;
    min-width: 100px;
  }
  
  .vue-title2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: calc(10px + 3vw);
    color: white;
    display: inline-block;
    padding-top: 0.5vh;
  }
  
  .vue-subtitle2 {
    font-family: 'Times New Roman', Times, serif;
    font-size: calc(4.5px + 0.8vw);
    color: white;
    padding-top: 2vh;

  }
  
  .background-image {
    background-image: url('~@/assets/abu.png');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }

.content{
  margin:50px
}
</style>
  