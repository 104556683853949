import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import 'vue-fullpage.js/dist/style.css'
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },
})

// FULLPAGE.js
import VueFullPage from 'vue-fullpage.js'
import 'font-awesome/css/font-awesome.min.css';


createApp(App).use(router).use(store).use(VueFullPage).use(vuetify).mount('#app')
